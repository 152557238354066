import React, { useState, useEffect } from "react";
import { GET_CLINIC_NAMES_URL } from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { selectQueryParams, setQueryParams } from "../../../features/SearchSlice";

function ClinicAutocomplete({ setClinicName }) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [clinics, setClinics] = useState([]);

  const queryParams = useSelector(selectQueryParams);

  useEffect(() => {
    AXIOS_GET(GET_CLINIC_NAMES_URL).then((res) => {
      setClinics(res.data);
      setSuggestions(res.data);
    });
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setClinicName(newValue);
    setInputValue(newValue);
    setSuggestions(clinics.filter(clinic => 
      clinic.facilityName && clinic.facilityName.toLowerCase().includes(newValue.toLowerCase())
    ));
    localStorage.setItem("searchVet", newValue);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.facilityName);
    setClinicName(suggestion.facilityName);
    setShowSuggestions(false);
    localStorage.setItem("searchVet", suggestion.facilityName);
  };

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => setShowSuggestions(false), 100);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        placeholder="NOM OU ÉTABLISSSEMENT"
        id="personal"
        onChange={handleInputChange}
        value={inputValue || localStorage.getItem("clinicName")}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{
          width: "100%", fontSize: "14px", padding: "8px", color: "#000"
        }}
      />
      {showSuggestions && suggestions.length > 0 && (
        <div
          className="suggestions-container"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "white",
            border: "1px solid #ccc",
            zIndex: 1000,
            maxHeight: "160px",
            overflowY: "auto",
            marginTop: "8px"
          }}
        >
          {suggestions.map((suggestion, index) => (
            suggestion.facilityName && (
            <div
              key={index}
              className="suggestion-item"
              onMouseDown={() => handleSuggestionClick(suggestion)}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "8px",
                borderBottom: "1px solid #eee",
                fontSize: "14px",
                color: "#000",
                textAlign: "left"
              }}
            >
              <img
                src={`https://v0.oneclic.vet/${suggestion.picture}`}
                alt={suggestion.facilityName}
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  marginRight: "8px"
                }}
              />
              {suggestion.facilityName}
            </div>
            )
          ))}
        </div>
      )}
    </div>
  );
}

export default ClinicAutocomplete;