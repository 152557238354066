import React, { useEffect } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import House from "../../../assets/Icons/clinic_house.png";
import Map from '../common/Map';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { useWindowSize } from '../../../hooks/useWindowSize';

function ClinicInactivePageContent() {

  const clinic = JSON.parse(localStorage.getItem("clinic"));
  const width = window.innerWidth;
  // Dummy data for the clinic


  return (
    // increase margin top margin on mobile
    <div className="max-w-4xl mx-auto mt-10 sm:px-8 px-4 py-10">
      {/* Clinic Header */}
      <div className="flex items-center gap-3 mb-2">
        <img src={House} alt="Clinic Icon" className="w-12 h-12" />
      </div>
      <h1 className="text-2xl font-bold">
        {/* #2CB5B2 increase padding left*/}
        <span className="text-[#2CB5B2] pr-2">{clinic.facilityName}</span>
      </h1>

      {/* Notice Section */}
      <div className="mb-6">
        <p className="text-gray-700">
          <span className="font-bold">Cet établissement n'est pas encore réservable en ligne sur Oneclic.vet.</span>{' '}
          <span className="font-medium">Vous êtes le propriétaire de cet établissement ?</span>
        </p>
        {/* underline */}
        <a 
          href="https://info.oneclic.vet/veterinaire-contactez-nous/" 
          className="text-[#2CB5B2] border-b border-[#2CB5B2]"
        >
          Inscrivez-vous sur Oneclic.vet
        </a>
      </div>

      {/* Address Section */}
      <div className="" style={{border: "1px solid #2CB5B2", borderRadius: "10px", padding: "10px"}}>
        <h2 className="text-lg font-bold text-[#2CB5B2] mb-4">
          Adresse
        </h2>
        <div className="flex items-start gap-3">
          <FaMapMarkerAlt className="text-[#2CB5B2] text-lg mt-1" />
          <div>
            <p className="text-black">{clinic.address}</p>
            <p className="text-black">{clinic.postalCode} {clinic.city} </p>
          </div>
        </div>
        {/* Map margin top 10px */}
        <div className="mt-4 w-full h-[200px] mt-8 overflow-hidden">
          <Map
            height={width < 1200 ? 200 : "calc(100% - 40px)"}
            address={clinic.address}
            city={clinic.city}
            postalCode={clinic.postalCode}
          />
        </div>
      </div>
    </div>
  );
}

export default ClinicInactivePageContent;
  