import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { googleMapsApiKey } from "../../../helper/Url";
import { animate } from "framer-motion";

const ClinicMarker = ({ position, title }) => {
  const [markerPosition, setMarkerPosition] = useState(position);

  useEffect(() => {
    setMarkerPosition(position);
  }, [position]);

  const shape = {
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: "poly",
  };

  return <Marker 
             position={markerPosition} 
             shape={shape} title={title} 
             animation={window.google.maps.Animation.BOUNCE} 
          />;
};

function ClinicMap({ height = "400px", address = "Sous la Falaise 30", city = "Portalban", postalCode = "04200"}) {
  const [center, setCenter] = useState();
  const [isGeocoded, setIsGeocoded] = useState(false);

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        const fullAddress = `${address}, ${postalCode} ${city}`;
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${googleMapsApiKey}`);
        const data = await response.json();
        if (data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setCenter({ lat: location.lat, lng: location.lng });
          setIsGeocoded(true);
        }
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    };

    geocodeAddress();
  }, [address, city, postalCode]);

  const mapOptions = {
    zoomControl: true,
    scrollwheel: false,
    draggable: true,
    disableDoubleClickZoom: true,
    disableDefaultUI: true, // Remove default controls
  };

  const containerStyle = {
    width: "100%",
    height: height,
  };

  const clinics = [{ name: "Clinic", position: center }];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });

  return isLoaded && isGeocoded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} options={mapOptions}>
      {clinics.map((clinic, index) => (
        <ClinicMarker key={index} position={clinic.position} title={clinic.name} />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(ClinicMap);
