import React from "react";
import AddAPhotoIcon from "../../../assets/Icons/Add-icon.png";
import Loader from "../../../helper/Loader/Loader";
import { AXIOS_POST } from "../../../config/axios";
import {
  POST_CLIENT_PICTURE_URL,
  POST_PETS_URL,
  POST_PET_PICTURE_URL,
  POST_PROVIDER_PIC_URL,
} from "../../../helper/Url";
import { useState } from "react";
import SuccessToast from "../../../helper/Message/SuccessToast";
import ErrorToast from "../../../helper/Message/ErrorToast";
import { ToastContainer } from "react-toastify";
import { set, useForm } from "react-hook-form";
import FormErrors from "../../../helper/Message/FormErrors";
import { useDispatch } from "react-redux";
import { setAnimalEdited } from "../../../features/AnimalsListChange";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";


const AddAnimal = () => {
  const userFirstname = localStorage.getItem("user_firstname");
  const token = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const id = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [sterilize, setSterilize] = useState(false);
  const [base64String, setbase64String] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addPicture = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader(); // Create a new FileReader object
    reader.onloadend = function () {
      // 'result' contains the base64 data of the uploaded file
      setbase64String(reader.result);
    };

    // Read the file as data URL (base64 format)
    reader.readAsDataURL(file);
  };
  const onhandleSubmit = (data) => {
    data.isInsured == "true"
      ? (data.isInsured = true)
      : (data.isInsured = false);
    data.isAnimalOut == "true"
      ? (data.isAnimalOut = true)
      : (data.isAnimalOut = false);
    data.sterilize = sterilize;

    setLoading(true);
    AXIOS_POST(POST_PETS_URL, data, token)
      .then((res) => {
        setLoading(false);
        dispatch(setAnimalEdited());
        // Convert base64 string to Blob
        try {
          var byteCharacters = atob(base64String.split(",")[1]);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "image/png" });

          // Create FormData object and append the Blob
          var formData = new FormData();
          formData.append("file", blob, "filename.png");
        } catch (error) {
          console.log(error);
        }

        AXIOS_POST(POST_PET_PICTURE_URL + `${res.data._id}`, formData, token)
          .then((res) => {})
          .catch((error) => {
            console.log(error);
          });
        SuccessToast("Animal ajouté avec succès");
        setTimeout(() => {
          navigate(`/customer/dashboard/animals/${res.data._id}`);
        }, 2000);
      })
      .catch((error) => {
        ErrorToast(error);
        setLoading(false);
      }); 
  };

  const onhandleSubmit2 = (data) => {
    ErrorToast("Veuillez remplir tous les champs obligatoires dans la description de l’animal avant de passer à autre chose ! et assurez-vous de l’avoir validé.");
  }


  return (
    <div className="content-container customer-content-container">
      <ToastContainer />
      <h1 className="welcome-title sp-header">
       Mon animal
      </h1>
      <h1 className="page-name">La fiche de l'animal</h1>
      <div className="pet-profile" >
        <div style={{ textAlign:"center", width:"100px" }}>
         <label htmlFor="petAnimal" style={{ cursor:"pointer" }}>
          <div className="profile-img-container" style={{ display:"grid", placeContent:"center" }}>
          <div style={base64String == null
                ? { height:"30px",width:"30px" }:{height:"96px",width:"96px"}}>
             <img
            src={
              base64String == null
                ? AddAPhotoIcon
                : base64String
            }
            alt=""
            className={base64String == null ? "" : ""}
          />

          </div>
          </div>
          
        
         </label>
         <label
                htmlFor="petAnimal"
                style={{ display: "flex", gap: "5px",cursor:"pointer",textAlign:"center",color:"#2CB5B2",fontWeight:"bold",fontSize:"14px" }}
              >
                <span> Ajouter une photo</span>
              </label>
              <input
                className="margin-bottom-26"
                name=""
                type="file"
                hidden
                id="petAnimal"
                onChange={(e) => addPicture(e)}
              />
        </div>
       
      </div>
      <div className="change-form">
         <form onSubmit={handleSubmit1(onhandleSubmit)}>
          <h1 className="form-subtitle">Description</h1>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Nom*</label>
              <input
                className="margin-bottom-26"
                name="name"
                onChange={handleInputChange}
                type="text"
                {...register("name", { required: true })}
                placeholder="Nom de l’animal*"
              />
              {errors1.name?.type === "required" && (
                <FormErrors>
                  Le nom de l'animal est obligatoire
                </FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">Sexe*</label>
              <select className="margin-bottom-26" onChange={handleInputChange} name="sex" id="" {...register("sex",{required:true})}>
                <option value="" selected disabled>
                  Sexe de l’animal*
                </option>
                <option value="Femelle">Femelle</option>
                <option value="Mâle">Mâle</option>
              </select>
              {errors1.sex?.type === "required" && (
                <FormErrors>
                  Le sexe de l'animal est obligatoire
                </FormErrors>
              ) }
            </div>
          </div>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Espèce*</label>
              <select className="margin-bottom-26" onChange={handleInputChange} name="species" id="" {...register("species",{required:true}) }>
                <option value="" selected disabled>
                  Espèce de l’animal*
                </option>
                <option value="Chat">Chat</option>
                <option value="Chien">Chien</option>
                <option value="NAC">NAC</option>
              </select>
              {errors1.species?.type === "required" && (
                <FormErrors>
                  L'espèce de l'animal est obligatoire
                </FormErrors>
              ) }
            </div>
            <div className="input-container">
              <label htmlFor="">Race*</label>
              <input
                className="margin-bottom-26"
                name="color"
                onChange={handleInputChange}
                { ...register("breed",{required:true}) }
                type="text"
                placeholder="Race de l’animal*"
              />

              {errors1.breed?.type === "required" && (
                <FormErrors>
                  Race de l'animal est obligatoire
                </FormErrors>
              ) }
            </div>
          </div>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Couleur</label>
              <input
                className="margin-bottom-26"
                name="color"
                onChange={handleInputChange}
                {...register("color")}
                type="text"
                placeholder="Couleur de l’animal"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Date de naissance de l’animal*</label>
              <input
                className="margin-bottom-26"
                name="birthdate"
                onChange={handleInputChange}
                type="date"
                {...register("birthdate",{required:true}) }
                placeholder="Date de naissance de l’animal*"
              />
              {errors1.birthdate?.type === "required" && (
                <FormErrors>
                  La date de naissance de l'animal est obligatoire
                </FormErrors>
              ) }
            </div>
          </div>
          <div className="flex-container  gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container"> 
            <div className="label-check">
                <span className="text-data">
                 L’animal est-il stérilisé ?
                </span>
                <div className="input-container checkbox-global">
                  <div className="checkbox_box">
                    <input
                      id="checkBox"
                      name="sterilize"
                      onChange={(e) => setSterilize(e.target.checked)}
                      type="checkbox"
                      hidden
                    />
                    <label htmlFor="checkBox">
                      <div className="disk"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="input-container">
              
            </div>
          </div>
          <div className="input-container btn-container">
            {loading ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les informations</button>
            )}
          </div>
         </form>
         <form onSubmit={handleSubmit2(onhandleSubmit2)}>
          <h1 className="form-subtitle">Informations médicales</h1>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Numéro de la puce</label>
              <input
                className="margin-bottom-26"
                {...register2("puceNumber")}
                name="puceNumber"
                onChange={handleInputChange}
                type="text"
                placeholder="Numéro de la puce de l’animal"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">L’animal est-il assuré ?</label>
              <select className="margin-bottom-26" onChange={handleInputChange} name="isInsured" id="" {...register2("isInsured")}>
                <option selected disabled>
                Assuré ?
                </option>
                <option value={"false"} >Non</option>
                <option value={"true"} > Oui</option>
              </select>
            </div>
          </div>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Date de la dernière vaccination*</label>
              <input
                className="margin-bottom-26"
                name="nextVaccinationDate"
                {...register2("nextVaccinationDate")}
                onChange={handleInputChange}
                type="date"
              />
              {errors2.nextVaccinationDate?.type === "required" && (
                <FormErrors>
                  La date de la dernière vaccination est obligatoire
                </FormErrors>
              ) }
            </div>
            <div className="input-container">
              <label htmlFor="">Date de rappel du vaccin de la rage*</label>
              <input
                className="margin-bottom-26"
                name="reminderDate"
                {...register2("reminderDate")}
                onChange={handleInputChange}
                type="date"
              />
              {errors2.reminderDate?.type === "required" && (
                <FormErrors>
                  La date de rappel du vaccin de la rage est obligatoire
                </FormErrors>
              ) }

            </div>
          </div>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Antécédents médicaux</label>
              <textarea
                name="medicalHistory"
                {...register2("medicalHistory")}
                onChange={handleInputChange}
                placeholder="L’animal a-t-il des antécédents médicaux ou chirurgicaux ? 
                Si oui, lesquels ?"
              ></textarea>
            </div>
            <div className="input-container">
              <label htmlFor="">
                L’animal a-t-il des pathologies ? Si oui, lesquelles ?
              </label>
              <textarea
                name="pathologies"
                {...register2("pathologies")}
                onChange={handleInputChange}
                placeholder="L’animal a-t-il des pathologies ? Si oui, lesquelles ?"
              ></textarea>
            </div>
          </div>
          <div className="input-container btn-container">
          {loading ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les informations</button>
            )}
          </div>
        </form>
        <form onSubmit={handleSubmit2(onhandleSubmit2)}>
          <h1 className="form-subtitle">Mode de vie</h1>
          <div className="flex-container gap-10" style={{ alignItems:"baseline" }}>
            <div className="input-container">
              <label htmlFor="">Nourriture donnée à l’animal</label>
              <input
                className="margin-bottom-26"
                name="allergies"
                {...register2("allergies")}
                onChange={handleInputChange}
                type="text"
                placeholder="Nourriture donnée à l’animal"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">L’animal sort-il ?</label>
              <select className="margin-bottom-26" onChange={handleInputChange} name="isAnimalOut" id="" {...register2("isAnimalOut")}>
                <option value="" selected disabled>
                  L’animal sort-il ?
                </option>
                <option value={"false"}>Non</option>
                <option value={"true"}>Oui</option>
              </select>
            </div>
          </div>
          <div className="input-container btn-container">
            {loading ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les informations</button>
            )} 
          </div>
        </form>


      </div>
    </div>
  );
};

export default AddAnimal;
