import React, { useEffect } from "react";
import "./App.scss";
import { Routes, Route, useLocation, useNavigate, Router, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import RegisterPage from "./pages/client/account/Register";
import LoginPage from "./pages/client/account/Login";
import ResetPage from "./pages/client/account/Reset";
import ChangePage from "./pages/client/account/Change";
import ForgetPage from "./pages/client/account/Forget";
import VerifyPage from "./pages/client/account/Verify";
import VetVerifyPage from "./pages/client/account/VetVerify";
import Home from "./pages/client/Home";
import ProtectedRoute from "./helper/Auth/ProtectedRoute";
import ClinicRegisterPage from "./pages/client/account/ClinicRegister";
import Board from "./components/client/customer/Board";
import ClinicBoard from "./components/client/clinic/Board";
import Account from "./components/client/customer/Account";
import ClinicAccount from "./components/client/clinic/Account";
import MyclinicInfo from "./components/client/customer/MyclinicInfo";
import MyclinicInfoClinic from "./components/client/clinic/MyclinicInfo";
import Animals from "./components/client/customer/Animals";
import AnimalDetail from "./components/client/customer/AnimalDetail";
import NotFound from "./components/common/NotFound";
import Rendezvous from "./components/client/customer/Rendezvous";
import CustomerDashboardPage from "./pages/client/customer/CustomerDashboard";
import ClinicDashboardPage from "./pages/client/clinic/ClinicDashboard";
import TimeTable from "./components/client/clinic/TimeTable";
import ClinicServices from "./components/client/clinic/Services";
import Veterinary from "./components/client/clinic/Veterinary";
import VeterinaryAdd from "./components/client/clinic/VeterinaryAdd";
import { General } from "./pages/client/search/General";
import { Booking } from "./pages/client/search/nextConfirm";
import { Confirm } from "./pages/client/search/Confirm";
import { Confirmed } from "./pages/client/search/Confirmed";
import { AXIOS_POST } from "./config/axios";
import { POST_SESSION_VERIFY_URL } from "./helper/Url";
import AddAnimal from "./components/client/customer/AddAnimal";
import RendezvousClinic from "./components/client/clinic/Rendezvous";
import ClinicAnimalDetail from "./components/client/clinic/AnimalDetail";
import InscriptionGeneral from "./pages/client/account/InscriptionGeneral";
import PreClientRegisterPage from "./pages/client/account/PreClientRegisterPage";
import PreClinicRegisterPage from "./pages/client/account/PreClinicRegisterPage";
import CompanyPreInfoPage from "./pages/client/account/CompanyPreInfoPage";
import ClientPreInfoPage from "./pages/client/account/ClientPreInfoPage";
import ScrollToTop from "./components/common/ScrollToTop";
import ClinicSummaryPage from "./pages/client/account/clinicSummaryPage";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route path="/" element={ <ScrollToTop><Home /></ScrollToTop>}></Route>
    <Route path="/cliniques/:clinicName" element={ <ScrollToTop><ClinicSummaryPage /></ScrollToTop>}></Route>
    <Route path="/forget" element={ <ScrollToTop><ForgetPage /></ScrollToTop>}></Route>
    <Route path="/verify" element={ <ScrollToTop><VerifyPage /></ScrollToTop>}></Route>
    <Route path="/vetverify" element={ <ScrollToTop><VetVerifyPage /></ScrollToTop>}></Route>
    <Route path="/register" element={ <ScrollToTop><RegisterPage /></ScrollToTop>}></Route>
    <Route path="/clinic" element={ <ScrollToTop><ClinicRegisterPage /></ScrollToTop>}></Route>
    <Route path="/preclientregister" element={ <ScrollToTop><PreClientRegisterPage /></ScrollToTop>}></Route>
    <Route path="/preclinicregister" element={ <ScrollToTop><PreClinicRegisterPage /></ScrollToTop>}></Route>
    <Route path="/insicription" element={ <ScrollToTop><InscriptionGeneral/></ScrollToTop>}></Route>
    <Route path="/search" element={ <General/>}></Route>
    <Route path="/search/next-booking" element={ <ScrollToTop><Booking/></ScrollToTop>}></Route>
    <Route path="/search/confirm-booking" element={ <ScrollToTop><Confirm/></ScrollToTop>}></Route>
    <Route path="/search/confirmed-booking" element={ <ScrollToTop><Confirmed/></ScrollToTop>}></Route>
    <Route path="/login" element={ <ScrollToTop><LoginPage /></ScrollToTop>}></Route>
    <Route path="/reset-password/:token" element={ <ScrollToTop><ResetPage /></ScrollToTop>}></Route>
    <Route
      path="/change"
      element={ <ScrollToTop>
        <ProtectedRoute>
          <ChangePage />
        </ProtectedRoute> 
      </ScrollToTop>}
    ></Route>
     <Route
      path="/companypreinfo"
      element={ <ScrollToTop>
        <ProtectedRoute>
          <CompanyPreInfoPage/>
        </ProtectedRoute> 
      </ScrollToTop>}
    ></Route>
     <Route
      path="/clientpreinfo"
      element={ <ScrollToTop>
        <ProtectedRoute>
          <ClientPreInfoPage/>
        </ProtectedRoute> 
      </ScrollToTop>}
    ></Route>
    <Route
      path="customer/dashboard/"
      element={ 
        <ProtectedRoute>
          <CustomerDashboardPage />
        </ProtectedRoute>
      }
    >
      <Route index element={ <ScrollToTop><Board /></ScrollToTop>}></Route>
      <Route index path="summary" element={ <ScrollToTop><Board /></ScrollToTop>}></Route>
      <Route path="account" element={ <ScrollToTop><Account /></ScrollToTop>}></Route>
      <Route path="animals" element={ <ScrollToTop><Animals /></ScrollToTop>}></Route>
      <Route path="animals/:id" element={ <ScrollToTop><AnimalDetail /></ScrollToTop>}></Route>
      <Route path="animals/add" element={ <ScrollToTop><AddAnimal /></ScrollToTop>}></Route>
      <Route path="customerclinicinfo" element={ <ScrollToTop><MyclinicInfo /></ScrollToTop>}></Route>
      <Route path="rendezvous" element={ <ScrollToTop><Rendezvous /></ScrollToTop>}></Route>
    </Route>
    <Route
      path="clinic/dashboard/"
      element={ 
        <ProtectedRoute>
          <ClinicDashboardPage />
        </ProtectedRoute>
      }
    >
      <Route index element={ <ScrollToTop><ClinicBoard /></ScrollToTop>}></Route>
      <Route path="summary" element={ <ScrollToTop><ClinicBoard /></ScrollToTop>}></Route>
      <Route path="account" element={ <ScrollToTop><ClinicAccount /></ScrollToTop>}></Route>
      <Route path="service" element={ <ScrollToTop><ClinicServices /></ScrollToTop>}></Route>
      <Route path="service/action" element={ <ScrollToTop><ClinicServices /></ScrollToTop>}></Route>
      <Route path="animals/:id" element={ <ScrollToTop><ClinicAnimalDetail /></ScrollToTop>}></Route>
      <Route path="veterinary" element={ <ScrollToTop><Veterinary /></ScrollToTop>}></Route>
      <Route path="veterinary/action" element={ <ScrollToTop><VeterinaryAdd /></ScrollToTop>}></Route>
      <Route path="myclinicinfo" element={ <ScrollToTop><MyclinicInfoClinic /></ScrollToTop>}></Route>
      <Route path="timetable" element={ <ScrollToTop><TimeTable /></ScrollToTop>}></Route>
      <Route path="rendezvous" element={ <ScrollToTop><RendezvousClinic /></ScrollToTop>}></Route>
    </Route>
    <Route path="*" element={ <ScrollToTop><NotFound /></ScrollToTop>}></Route>
    </>
  )
)


function App() {
  return (
    <div className="App">
       <RouterProvider router={router}/>
    </div>
  );
}

export default App;
