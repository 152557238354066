export const googleMapsApiKey = "AIzaSyDOY52mdGGBLDSWg6JOPbA7fuN6GIKFrfM";

// Session

export const POST_LOGIN_URL = "/session";
export const POST_SESSION_VERIFY_URL = "/session/verify";
export const POST_CHANGE_URL = "/users/changePassword";
export const POST_RESET_URL = "/users/resetPassword";
export const POST_FORGET_URL = "/users/forgotPassword";
export const POST_GOOGLE_LOGIN = "/session/google/login";
export const PUT_CREDENTIALS_URL = "/users/credentials";

// User api

export const POST_UPDATE_USER_URL = "/auth/update";
export const POST_GET_USER_URL = "/users/";
export const POST_REGISTER_URL = "/users";
export const POST_REGISTER_GOOGLE_URL = "/users/google";
export const POST_VERIFY_URL = "/users/verify";
export const POST_VERIFY_RESEND_URL = "/users/resendotp";

// Clinic api

export const GET_CLINICS_URL = "/clinics";
export const GET_SINGLE_CLINIC_URL = "/clinics/";
export const UPDATE_CLINIC_INFO_URL = "/clinics/";
export const GET_SEARCH_CLINICS_NEAREST_URL = "/clinics/nearest";
export const GET_SEARCH_CLINICS_NEAREST_WITH_SLOTS_URL = "/clinics/nearest-with-slots";
export const POST_CLINICS_URL = "/clinic_timetable";
export const POST_CLINICS_PIC_URL = "/clinics/picture/";
export const GET_CLINIC_TOKEN_VALIDITY_URL = "/session/verify";
export const GET_CLINIC_CITIES_URL = "/clinics/available-cities";
export const GET_CLINIC_NAMES_URL = "/clinics/available-clinics";

// Clinic Timetable

export const GET_CLINIC_URL = "/clinic_timetable";
export const GET_CLINIC_TIMETABLE_CLINICS_URL = "/clinic_timetable/clinics/";
export const GET_CLINIC_TIMETABLE_DAYS_URL = "/clinic_timetable/days/clinics/";
export const GET_CLINIC_TIMETABLE_URL = "/clinic_timetable/";
export const UPDATE_CLINIC_TIMETABLE_URL = "/clinic_timetable/";
export const POST_CLINICS_TIMETABLE_URL = "/clinic_timetable/initialize";
export const UPDATE_CLINIC_TIMETABLE_DISABLE_DAY_URL =
  "/clinic_timetable/disable/";
export const UPDATE_CLINIC_TIMETABLE_ENABLE_DAY_URL =
  "/clinic_timetable/enable/";
export const UPDATE_CLINIC_TIMETABLE_DISABLE_SLOT_URL =
  "/clinic_timetable/slots/remove/";
export const UPDATE_CLINIC_TIMETABLE_ENABLE_SLOT_URL =
  "/clinic_timetable/slots/add/";
export const DELETE_CLINIC_TIMETABLE_URL = "/clinic_timetable/";
export const PATCH_SLOT_DURATION_URL = "/clinic_timetable/slots/update/";

// Client

export const GET_CLIENTS_URL = "/clients";
export const GET_SINGLE_CLIENTS_URL = "/clients/";
export const UPDATE_CLIENTS_URL = "/clients/";
export const POST_CLIENT_PICTURE_URL = "/clients/picture/";

// Providers

export const POST_PROVIDERS_URL = "/providers";
export const GET_PROVIDERS_URL = "/providers";
export const UPDATE_PROVIDER_URL = "/providers/";
export const GET_SINGLE_PROVIDER_URL = "/providers/";
export const DELETE_SINGLE_PROVIDER_URL = "/providers/";
export const GET_PROVIDERS_CLINIC_PROVIDER_URL = "/providers/clinics/";
export const GET_CLINIC_SERVICES_URl = "/clinic/services/";
export const UPDATE_PROVIDERS_URL = "/providers/";
export const POST_PROVIDER_URL = "/providers/email";
export const UPDATE_PROVIDER_ENABLE_URL = "/providers/";
export const UPDATE_PROVIDER_DISABLE_URL = "/providers/";
export const POST_PROVIDER_DUPLICATE_URL = "/providers/duplicate/";

// Providers Timetable

export const POST_PROVIDERS_TIMETABLE_INITIALIZE_URL =
  "/provider_timetable/initialize/";
export const GET_PROVIDER_TIMETABLE_URL = "/provider_timetable/";
export const UPDATE_PROVIDER_TIMETABLE_DISABLE_DAY_URL =
  "/provider_timetable/disable/";
export const UPDATE_PROVIDER_TIMETABLE_ENABLE_DAY_URL =
  "/provider_timetable/enable/";
export const UPDATE_PROVIDER_TIMETABLE_DISABLE_SLOT_URL =
  "/provider_timetable/slots/remove/";
export const UPDATE_PROVIDER_TIMETABLE_ENABLE_SLOT_URL =
  "/provider_timetable/slots/add/";
export const POST_PROVIDER_AVAILABLE_URL = "/providers/available";
export const POST_PROVIDER_PIC_URL = "/providers/picture/";
export const PATCH_PROVIDER_SLOT_DURATION_URL =
  "/provider_timetable/slots/update/";

// Service

export const POST_SERVICE_URL = "/services";
export const GET_SERVICES_URL = "/services";
export const GET_SERVICS_CLINIC_URL = "/services/clinics/";
export const GET_SINGLE_SERVICE_URL = "/services/";
export const UPDATE_SERVICE_URL = "/services/";
export const DELETE_SERVICE_URL = "/services/";
export const UPDATE_SERVICE_STATUS_URL = "/services/";
export const UPDATE_SERVICE_ENABLE_URL = "/services/available/";
export const POST_SERVICE_SPECIES_URL = "/services/available";
export const POST_SERVICE_PIC_URL = "/services/picture/";
export const POST_SERVICE_DUPLICATE_URL = "/services/duplicate/";

//Service Timetable

export const POST_SERVICE_TIMETABLE_INITIALIZE_URL =
  "/service_timetable/initialize/";
export const GET_SINGLE_SERVICE_TIMETABLE_URL = "/service_timetable/";
export const UPDATE_SERVICE_TIMETABLE_DAY_DISABLE_URL =
  "/service_timetable/disable/";
export const UPDATE_SERVICE_TIMETABLE_DAY_ENABLE_URL =
  "/service_timetable/enable/";
export const UPDATE_SERVICE_TIMETABLE_SLOT_DISABLE_URL =
  "/service_timetable/slots/remove/";
export const UPDATE_SERVICE_TIMETABLE_SLOT_ENABLE_URL =
  "/service_timetable/slots/add/";
export const DELETE_SERVICE_TIMETABLE_URL = "/service_timetable/";
export const UPDATE_SERVICE_TIMETABLE_URL = "/service_timetable/";
export const PATCH_SERVICE_SLOT_DURATION_URL =
  "/service_timetable/slots/update/";
// Pet

export const POST_PETS_URL = "/pets";
export const GET_PETS_URL = "/pets";
export const UPDATE_PETS_URL = "/pets/";
export const DELETE_PETS_URL = "/pets/";
export const GET_SINGLE_PET_URL = "/pets/";
export const POST_PET_PICTURE_URL = "/pets/picture/";

// Appointment

export const POST_APPOINTMENT_URL = "/appointments";
export const GET_APPOINTMENTS_URL = "/appointments";
export const DELETE_APPOINTMENTS_URL = "/appointments/";
export const GET_APPOINTMENT_CLINIC_URL = "/appointments";

// special days

export const POST_SPECIAL_DAYS_URL = "/special-day";
export const GET_SPECIAL_DAYS_URL = "/special-day";
export const UPDATE_SPECIAL_DAYS_URL = "/special-day/spacial";
export const DELETE_SPECIAL_DAYS_URL = "/special-day/spacial";

// availibility of provider and service

export const GET_SLOTS_AVAILABILITY = "/clinics/availableSlots";

// Iframe API

export const GET_IFRAME_SPECIES_URL = "/species";
