import React, { useCallback } from "react";
import Translate from "../../../config/Translate";
import {
  GET_SINGLE_CLIENTS_URL,
  UPDATE_CLIENTS_URL,
  
} from "../../../helper/Url";
import Loader from "../../../helper/Loader/Loader";
import { AXIOS_GET, AXIOS_PATCH } from "../../../config/axios";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import SuccessToast from "../../../helper/Message/SuccessToast";
import { selectBookinginfo } from "../../../features/SearchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormErrors from "../../../helper/Message/FormErrors";
import { ReactComponent as AddRed } from "../../../assets/Icons/AddRed.svg";
import { Box, Modal } from "@mui/material";
import { selectUserInfo } from "../../../features/UserInfoSlice";
import PhoneField from "../../common/PhoneField";

// 1 style for the modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 5,
  borderColor: 'primary.main',
  boxShadow: 24,
  color:"black",
  p: 4,
};

const MyclinicInfo = () => {
  const userFirstname = localStorage.getItem("user_firstname");
  const userLastname = localStorage.getItem("user_lastname");
  const token = localStorage.getItem("user_token");
  const bookingData = useSelector(selectBookinginfo).payload;
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  const id = localStorage.getItem("user_id");
  // 2 states to check if the form is saved or not
  const [datasaved, setDatasaved] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ latitude: 0, longitude: 0 });
  const [telephone, setTelephone] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // watch for changes in the form
  const newData = watch();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const onhandleSubmit = (data) => {
    setLoading(true);
    data.telephone = telephone;
    AXIOS_PATCH(UPDATE_CLIENTS_URL + `${id}`, data, token)
      .then((res) => {
        SuccessToast("Vos informations ont été mises à jour avec succès");
        setLoading(false);
        setUserInfo(res.data);
        // user firstname and lastname is stored in local storage
        localStorage.setItem("user_firstname", res.data.firstname);
        localStorage.setItem("user_lastname", res.data.lastname);
        dispatch(selectUserInfo(res.data));
        getCustomerInfo();
        // if booking data contains informations then navigate to confirm booking page
        if (bookingData?.selectedDate?.length > 0) {
          setTimeout(() => {
            navigate("/search/confirm-booking");
          }, 100);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getCustomerInfo = () => {
    AXIOS_GET(GET_SINGLE_CLIENTS_URL + `${id}`, token)
      .then((res) => {
        setUserInfo(res.data);
        Object.keys(res.data).forEach((key) => {
          if (key === "birthdate") {
            setValue(key, res.data[key].slice(0, 10));
            return;
          }
          if (key === "country") {
            setValue(key, "Suisse");
            return;
          }
          if (key === "telephone") {
            setTelephone(res.data[key]);
            return;
          }
          setValue(key, res.data[key]);
        });
      })
      .catch((error) => {
        console.log("The response error is :", error);
      });
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

   // 3.  watch for changes in the new form

   useEffect(() => {
    if ( newData.email !== userInfo.email  || newData.address !== userInfo.address || newData.postalCode !== userInfo.postalCode || newData.city !== userInfo.city || newData.title !== userInfo.title || newData.civility !== userInfo.civility || newData.firstname !== userInfo.firstname || newData.lastname !== userInfo.lastname ) {
      setDatasaved(false);
    } else {
      setDatasaved(true);
    }
  }, [newData, userInfo]);
  

  // 4. blocker

  let shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
    {
      return !datasaved && currentLocation.pathname !== nextLocation.pathname
    },
    [datasaved]
  ); 
  let blocker = useBlocker(shouldBlock);

  // 5. Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && datasaved) {
      blocker.reset();
    }
  }, [blocker, datasaved]);

  const saveUserInfo = () =>{
     onhandleSubmit(newData)
     blocker.proceed();
  }

  return (
    <div className="content-container customer-content-container">
      <ToastContainer />
      {blocker.state === "blocked"   &&
        <Modal open="true">
          <Box sx={style}>
              <div>
               Souhaitez-vous sauvegarder vos modifications avant de quitter cette page ?
              </div>
              <div className="info-actio-btn">
              <button  onClick={() => blocker.reset()} className="customerBtnlight">Non</button>
              <button onClick={saveUserInfo} className="customerBtn">Oui</button>
              </div>
            </Box>
        
        </Modal>
      }
      <h1 className="welcome-title">
        <Translate Word={"page.Bienvenue"} />{" "}
        <span>{userFirstname && userFirstname} { userLastname&&userLastname}</span>
      </h1>
      <h1 className="page-name">
        <Translate Word={"page.myinfoenunclinic"} />
      </h1>
      <div className="change-form page-name sm-page-name">
        <form className="" onSubmit={handleSubmit(onhandleSubmit)}>
          <div
            className="flex-container gap-10 margin-bottom-13"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">Civilité*</label>
              <select
                className="margin-bottom-13"
                id=""
                name="civility"
                {...register("civility", { required: true })}
                onChange={handleInputChange}
                value={formData?.civility}
              >
                <option value="" selected disabled>
                  Civilité*
                </option>
                <option value="Mr">Monsieur</option>
                <option value="Mrs">Madame</option>
                <option value="Ms">Mademoiselle</option>
              </select>
              {errors.civility?.type === "required" && (
                <FormErrors>La civilité est requis</FormErrors>
              )}
            </div>
            
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.datedenaissance"} />
              </label>
              <input
                className="margin-bottom-13"
                type="date"
                placeholder="Votre date de naissance*"
                {...register("birthdate", { required: true })}
                name="birthdate"
                onChange={handleInputChange}
              />
              {errors.birthdate?.type === "required" && (
                <FormErrors>Le date de naissance est requis</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10 margin-bottom-13"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.prenom"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre prénom*"
                {...register("firstname", { required: true })}
                name="firstname"
                onChange={handleInputChange}
                value={formData.firstname}
              />
              {errors.firstname?.type === "required" && (
                <FormErrors>Le prénom est requis</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.nodefamily"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre nom de famille*"
                {...register("lastname", { required: true })}
                name="lastname"
                onChange={handleInputChange}
                value={formData.lastname}
              />
              {errors.lastname?.type === "required" && (
                <FormErrors>Le nom de famille est requis</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10 margin-bottom-13"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.address"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre adresse*"
                {...register("address", { required: true })}
                name="address"
                onChange={handleInputChange}
                value={formData.address}
              />
              {errors.address?.type === "required" && (
                <FormErrors>L'adresse est requis</FormErrors>
              )}
            </div>
            
          </div>
          <div
            className="flex-container gap-10 margin-bottom-13"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.postalcode"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre code postal*"
                name="postalCode"
                {...register("postalCode", { required: true })}
                onChange={handleInputChange}
                value={formData.postalCode}
              />
              {errors.postalCode?.type === "required" && (
                <FormErrors>Carte postal est requis</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.ville"} />
              </label>
              <input
                className="margin-bottom-13"
                type="text"
                placeholder="Votre ville*"
                name="city"
                {...register("city", { required: true })}
                onChange={handleInputChange}
                value={formData.city}
              />
              {errors.city?.type === "required" && (
                <FormErrors>La ville est requis</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10 margin-bottom-13"
            style={{ alignItems: "baseline" }}
          >
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.pay"} />
              </label>
              <select
                className="margin-bottom-13"
                id=""
                name="country"
                {...register("country", { required: true, value: "Suisse"})}
                onChange={handleInputChange}
                value={formData.country}
              >
                <option value="" selected disabled>
                  Votre pays*
                </option>
                <option value="Suisse">Suisse</option>
              </select>
              {errors.country?.type === "required" && (
                <FormErrors>Le pays est requis</FormErrors>
              )}
            </div>
            <div className="input-container">
              <label htmlFor="">Titre</label>
              <select
                className="margin-bottom-13"
                id=""
                name="title"
                {...register("title", { required: true })}
                value={formData.title}
                onChange={handleInputChange}
              >
                <option selected disabled>
                  Titre
                </option>
                <option value="Dr">Dr.</option>
                <option value="Mme">Mme.</option>
                <option value="Mlle">Mlle.</option>
                <option value="M">M.</option>
                <option value="Me">Me.</option>
                <option value="Pr">Pr.</option>
              </select>
              {errors.title?.type === "required" && (
                <FormErrors>Le titre est requis</FormErrors>
              )}
            </div>
          </div>
          <div
            className="flex-container gap-10 margin-bottom-13"
            style={{ alignItems: "baseline" }}
          >
    
           
            <div className="input-container">
              <label htmlFor="">
                <Translate Word={"page.telephone"} />*
              </label>
              {/* <div className="input-icon margin-bottom-13 flex-container">
                <AddRed />
                <input
                  type="number"
                  placeholder="Votre téléphone*"
                  name="telephone"
                  onChange={handleInputChange}
                  {...register("telephone", {
                    required: "Le téléphone est requis",
                    pattern: {
                      value: /^41\d{9}$/,
                      message:
                        "Le téléphone doit commencer par 41 et avoir 11 chiffres (41xxxxxxxxx)",
                    },
                  })}
                  value={formData.telephone}
                />
              </div> */}
              <PhoneField 
                     containerStyle={{ width:"100%", height:"52px",marginBottom:"20px" }}
                     buttonStyle={{ borderColor:"#2CB5B2" }}  
                     inputStyle={{ width:"100%",height:"52px",borderColor:"#2CB5B2" }}
                     placeholder="Votre téléphone*"  
                     telephone={telephone}
                     settelephone={setTelephone}
                />
              {errors.telephone?.type === "required" && (
                <FormErrors>Le téléphone est requis</FormErrors>
              )}
              {errors.telephone?.type === "pattern" && (
                <FormErrors>
                  Le téléphone doit commencer par 41 et avoir 11 chiffres (41xxxxxxxxx)
                </FormErrors>
              )}
            </div>
            <div className="input-container">
              </div>
          </div>
          <div className="input-container btn-container">
            {loading ? (
              <button type="button">
                <Loader color="#ffffff"></Loader>
              </button>
            ) : (
              <button>Valider les modifications</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyclinicInfo;
