import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    searchResult: [],
    inactiveClinics: [],
    selectedInactiveClinic: null,
    bookingInfo:[],
    queryParams:{},
    pagination:{
        currentPage:1,
        totalPages:0
    }
};

export const SearchSlice = createSlice({
  name: "-",
  initialState,
  reducers: {
    setSearchedClinics: (state, payload) => {
      state.searchResult = payload;
    },
    setInactiveClinics: (state, payload) => {
      state.inactiveClinics = payload;
    },
    setBookingInfo: (state, payload) => {
      state.bookingInfo = payload;
    },
    setQueryParams: (state, payload) => {
      state.queryParams = payload;
    },
    setPagination: (state, payload) => {
      state.pagination = payload;
    },
    setSelectedInactiveClinic: (state, payload) => {
      state.selectedInactiveClinic = payload;
    }
  },
});

export const {setSearchedClinics, setInactiveClinics, setBookingInfo, setQueryParams, setPagination, setSelectedInactiveClinic} = SearchSlice.actions;

export const selectSearchResult = (state) => state.search.searchResult;
export const selectInactiveClinics = (state) => state.search.inactiveClinics;
export const selectBookinginfo = (state) => state.search.bookingInfo;
export const selectQueryParams = (state) => state.search.queryParams;
export const selectPagination = (state) => state.search.pagination;
export const selectSelectedInactiveClinic = (state) => state.search.selectedInactiveClinic;
export default SearchSlice.reducer;

