import React, { useState, useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { AXIOS_GET } from "../../../config/axios";
import { GET_CLINIC_CITIES_URL } from "../../../helper/Url";
import { useSelector } from "react-redux";
import { selectQueryParams } from "../../../features/SearchSlice";

function PlaceAutocomplete({
  placeholder = "Entrez un emplacement",
  updateLocation,
  value,
  onChange,
  setCordinate,
  name,
  setAddress,
  setSearchPostal,
  setCity
}) {
  const queryParams = useSelector(selectQueryParams);
  const [place, setPlace] = useState(null);
  const [inputValue, setInputValue] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    AXIOS_GET(GET_CLINIC_CITIES_URL).then((res) => {
      setSuggestions(res.data);
      setCities(res.data);
    });
  }, []);

  useEffect(() => {
    if (place) {
      getGeolocation();
    }
  }, [place]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const onLoad = (autocomplete) => {
    autocomplete.addListener("place_changed", () => {
      const selectedPlace = autocomplete.getPlace();
      setPlace(selectedPlace);
      
      if (selectedPlace.formatted_address) {
        setInputValue(selectedPlace.formatted_address);
      }
    });
  };

  const getGeolocation = () => {
    if (place && place.geometry && place.geometry.location) {
      const { lat, lng } = place.geometry.location;
      if (updateLocation) {
        updateLocation(
          lat(), 
          lng(), 
          place.formatted_address,
          place.address_components
        );
      }
      if (setCordinate) {
        setCordinate({
          lat: lat(),
          lng: lng(),
          place: place.formatted_address,
        });
      }
      if (setAddress) {
        setAddress(place.formatted_address);
      }
      if (setSearchPostal) {
        setSearchPostal(place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '');
      }
      if (setCity) {
        setCity(place.address_components.find(component => component.types.includes('locality'))?.long_name || '');
      }
      return `Latitude: ${lat()}, Longitude: ${lng()}`;
    }
    return "no data";
  };

  const handleInputChange = async (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setShowSuggestions(true);
    // Convert both input and city names to lowercase for case-insensitive comparison
    setSuggestions(cities.filter(city => city.toLowerCase().includes(newValue.toLowerCase())));

    const syntheticEvent = {
      target: {
        name: name,
        value: newValue
      }
    };
    
    if (onChange) {
      onChange(syntheticEvent);
    }
  };

  const handleFocus = () => {
    setShowSuggestions(true);
    setSuggestions(cities);
  };
  const handleBlur = () => {
    setTimeout(() => setShowSuggestions(false), 100);
  };
  const handleSuggestionClick = async (suggestion) => {
    setInputValue(suggestion);
    setShowSuggestions(false);

    // Use Google Places AutocompleteService to get place details
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: suggestion }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions.length > 0) {
        const placeId = predictions[0].place_id;
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        placesService.getDetails({ placeId }, (placeDetails, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPlace(placeDetails);
          }
        });
      }
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={getGeolocation}
        options={{ componentRestrictions: { country: "ch" } }}
        className="w-full"
      >
        <input
          type="text"
          autoComplete="off"
          onChange={handleInputChange}
          value={inputValue || localStorage.getItem("address")}
          className="border_purple text-black bg-white w-full"
          placeholder={placeholder}
          name={name}
          style={{
            width: "100%", fontSize: "14px"
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Autocomplete>
      {showSuggestions && suggestions.length > 0 && (
        <div
          className="suggestions-container"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "white",
            border: "1px solid #ccc",
            zIndex: 1000,
            maxHeight: "170px",
            overflowY: "auto",
            marginTop: "8px"
          }}
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion-item"
              onMouseDown={() => handleSuggestionClick(suggestion)}
              style={{
                cursor: "pointer",
                padding: "8px",
                borderBottom: "1px solid #eee",
                color: "#000",
                fontSize: "14px",
                textAlign: "left",
                width: "100%"
              }}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PlaceAutocomplete;
